/** @format */

import ControllerAdapter, { Model, Paginator } from "@api/auth/ControllerAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";
import { TypesOption } from "@context/LocationProvider";
import { Feature } from "ol";
import { Geometry } from "ol/geom";

export interface ApparatusLocation extends Model {
    location: string;
    apparatuses_locations_place?: ApparatusPlaces | null;
}

export interface ApparatusPlaces extends Model {
    location: string;
    geoPoint: string | null;
    apparatuses_location?: ApparatusLocation | null;
}

export interface ApparatusLocationPlaceModel extends Model {
    apparatuses_location_id: number;
    location: string;
    geoPoint?: string;
}

export interface ApparatusLocationModel extends Model {
    location: string;
}

export interface ApparatusLocationBaseModel extends Model {
    id: number;
    apparatuses_locations_id?: number;
    apparatuses_locations_places_id?: number;
    info: string;
    apparatuses_locations_place?: ApparatusLocationPlaceModel | null;
    apparatuses_location?: ApparatusLocationModel;
}

export interface ApparatusPlacesAdd {
    apparatus_id: number;
    location_id: string;
    places_id: string;
    info?: string;
    isNew: boolean;
    id?: number;
}

export interface LocationPlace {
    id: number;
    place: string;
    location: string;
}

export interface ApparatusPlacesAddSubmit {
    success: boolean;
    location_id?: number;
}

export interface ApparatusLocationAdd {
    success: boolean;
    id?: number;
    errors?: unknown;
}

export interface ApparatusLocationAdapterPaginator extends Paginator {
    apparatusesLocationsPlaces: Model[];
}

export default class ApparatusLocationAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    Model,
    Paginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super(
            "apparatusesLocationsPlaces",
            "apparatusesLocationsPlace",
            "apparatusesLocationsPlaces",
            adapter
        );
    }

    public async getLocation(): Promise<ApparatusLocation[]> {
        const { data } = await this._get<{ apparatusesLocations: ApparatusLocation[] }>(
            `getLocation`
        );

        return data.apparatusesLocations;
    }

    public async getDefaultLocation(): Promise<string | null> {
        const { data } = await this._get<{ location: string | null }>(`getDefaultLocation`);

        return data.location;
    }

    public async getPlaces(id: string): Promise<ApparatusPlaces[]> {
        const { data } = await this._get<{ apparatusesPlaces: ApparatusPlaces[] }>(
            `getPlaces/${id}`
        );

        return data.apparatusesPlaces;
    }

    public async addLocationPoint(
        point: string,
        id: number | string
    ): Promise<{ success: boolean; id: string }> {
        const { data } = await this._post<{ success: boolean; id: string }>(
            `addLocationPoint/${id}`,
            {
                point,
            }
        );

        return data;
    }

    public async addLocation(location: TypesOption): Promise<ApparatusLocationAdd> {
        const { data } = await this._post<ApparatusLocationAdd>(`addLocation`, {
            location,
        });

        return data;
    }

    public async addPlace(
        location: TypesOption,
        locationId: string
    ): Promise<ApparatusLocationAdd> {
        const { data } = await this._post<ApparatusLocationAdd>(`addPlace`, {
            location,
            locationId,
        });

        return data;
    }

    public async updateLocationPoint(point: string, id: string): Promise<{ success: boolean }> {
        const { data } = await this._post<{ success: boolean }>(`updateLocationPoint`, {
            point,
            id,
        });

        return data;
    }

    public async updateLocationPlace(point: string, id: string): Promise<boolean> {
        const { data } = await this._post<{ success: boolean }>(`updateLocationPlace/${id}`, {
            point,
        });

        return data.success;
    }

    public async removeLocationPoint(id: string): Promise<boolean> {
        const { data } = await this._get<{ success: boolean }>(`removeLocationPoint/${id}`);

        return data.success;
    }

    public async getLocationPoint(locationId: string): Promise<Feature<Geometry>[]> {
        const { data } = await this._get<{ points: Feature<Geometry>[] }>(
            `getLocationPoint/${locationId}`
        );

        return data.points;
    }

    public async submitLocation(submitData: ApparatusPlacesAdd): Promise<ApparatusPlacesAddSubmit> {
        const { data } = await this._post<ApparatusPlacesAddSubmit>(`add`, submitData);

        return data;
    }

    public async getLocationFromStr(location: string): Promise<string | undefined> {
        const { data } = await this._post<{ locationResult?: string }>(`getLocationFromStr`, {
            location,
        });

        return data.locationResult;
    }
}
