/** @format */

import ControllerAdapter, { Model, Paginator } from "@api/auth/ControllerAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";

export interface ApparatusSingleStatisticModel {
    value: number;
    percentage: number;
}
export interface ApparatusStatisticsModel {
    number_of_operational: ApparatusSingleStatisticModel;
    number_of_not_due_soon: ApparatusSingleStatisticModel;
    number_of_due_soon: ApparatusSingleStatisticModel;
    number_of_due: ApparatusSingleStatisticModel;
    number_of_same_day: ApparatusSingleStatisticModel;
    number_of_within_a_year: ApparatusSingleStatisticModel;
    number_of_overdue: ApparatusSingleStatisticModel;
    number_of_monitoring: ApparatusSingleStatisticModel;
    number_of_without_monitoring: ApparatusSingleStatisticModel;
    number_of_monitoring_and_not_archived: ApparatusSingleStatisticModel;
    number_of_archived: ApparatusSingleStatisticModel;
    number_of_not_operational: ApparatusSingleStatisticModel;
    number_of_action_required: ApparatusSingleStatisticModel;
    number_of_out_of_house: ApparatusSingleStatisticModel;
    number_of_undetectable: ApparatusSingleStatisticModel;
    number_of_decommissioned: ApparatusSingleStatisticModel;
    number_of_locked: ApparatusSingleStatisticModel;
    sum_for_operational_statistic: number;
    sum_for_due_statistic: number;
    sum_apparatuses: number;
}

export interface ApparatusStatisticsUserModel {
    number_of_operational: ApparatusSingleStatisticModel;
    number_of_not_operational: ApparatusSingleStatisticModel;
    sum_apparatuses: number;
}
export interface InspectionResultModelPaginator extends Paginator {
    inspectionResults: ApparatusStatisticsModel[];
}

export default class StatisticAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    Model,
    Paginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("statistics", "statistic", "statistics", adapter);
    }

    public async getApparatusesStatistics(): Promise<ApparatusStatisticsModel> {
        const { data } = await this._get<{ apparatuses_statistics: ApparatusStatisticsModel }>(
            `apparatuses`
        );

        return data.apparatuses_statistics;
    }

    public async getCountUser(id: string): Promise<ApparatusStatisticsUserModel> {
        const { data } = await this._get<{ apparatuses_statistics: ApparatusStatisticsUserModel }>(
            `getCountUser/${id}`
        );

        return data.apparatuses_statistics;
    }

    public async getCountCostcenterUser(id: string): Promise<ApparatusStatisticsUserModel> {
        const { data } = await this._get<{ apparatuses_statistics: ApparatusStatisticsUserModel }>(
            `getCountCostcenterUser/${id}`
        );

        return data.apparatuses_statistics;
    }
}
