/** @format */

import { Spinner, Flex, Text } from "@chakra-ui/react";
import LoadingContext from "@context/LoadingProvider";
import { motion } from "framer-motion";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FiAlertTriangle, FiX } from "react-icons/fi";

import "./PopUp.scss";

const PopUp: React.FC = () => {
    const [t] = useTranslation();

    const { saved, handleClose } = useContext(LoadingContext);

    const variants = {
        open: { opacity: 1, y: "-35px" },
        closed: { opacity: 0, y: "100%" },
    };

    return (
        <>
            <motion.div
                className={`popup ${saved.type === "successful" ? "popup-success" : ""}`}
                animate={saved.show ? "open" : "closed"}
                variants={variants}
            >
                <Flex flexDirection="column">
                    <Flex justifyContent="space-between" width="100%">
                        <Flex flexDirection="column">
                            <Text as="span" mb={3} fontWeight={600}>
                                {saved.type === "successful"
                                    ? t("popup.success.headline", "Erfolgreich")
                                    : t("popup.fail.headline", "Fehlgeschlagen")}
                            </Text>
                            <Text as="span">
                                {saved.text ? (
                                    saved.text
                                ) : (
                                    <>
                                        {saved.type === "successful"
                                            ? t("popup.success.text", "Es wurde gespeichert")
                                            : t(
                                                  "popup.fail.text",
                                                  "Es konnte nicht gespeichert werden"
                                              )}
                                    </>
                                )}
                            </Text>
                        </Flex>
                        <div onClick={() => handleClose()}>
                            <FiX />
                        </div>
                    </Flex>
                    <Flex mt={3} justifyContent="center" alignItems="center">
                        {saved.type === "successful" ? (
                            <Spinner size="xl" />
                        ) : (
                            <FiAlertTriangle size={25} />
                        )}
                    </Flex>
                </Flex>
            </motion.div>
        </>
    );
};

export default PopUp;
